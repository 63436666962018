import React from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import Layout from "components/layout"
import Modal, { ModalBody } from "components/Modal"
import CheckLogo from "static/images/icons-ic-system-checkbox-circle-fill.png"
import { t } from "src/i18n/config"

const MessageContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Img = styled.img`
  width: 64px;
  height: 64px;
  object-fit: contain;
`

const Title = styled.div`
  margin-top: 8px;
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.07px;
  text-align: center;
`
const Description = styled.div`
  margin: 16px 48px 0 48px;
  line-height: 1.6;
  letter-spacing: 0.08px;
  text-align: center;
`

const Create = () => {
  return (
    <Layout>
      <Helmet>
        <title>{t("basicInformationSetup")}</title>
      </Helmet>
      <Modal>
        <ModalBody>
          <MessageContainer>
            <Img src={CheckLogo} alt="" />
            <Title>{t("dataAddedSuccessfully")}</Title>
            <Description>{t("bindingSuccess")}</Description>
          </MessageContainer>
        </ModalBody>
      </Modal>
    </Layout>
  )
}

export default Create
